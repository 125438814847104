import { Fade, Slide } from 'react-awesome-reveal';

import useIsMobileQuery from '../../hooks/use-mobile-query';

interface InfoSectionProps {
  title: {
    primary: string;
    secondary: string;
  };
  icons: {
    label: string;
    soruce: string;
    width: number;
    height: number;
    link?: string;
  }[];
  content: {
    text: string;
    imageSource: string;
  };
  subtitle: string;
  iconSectionTitle: string;
  isReversed?: boolean;
  isDark?: boolean;
  lang?: string;
}

const InfoSection: React.FC<InfoSectionProps> = (props) => {
  const isMobile = useIsMobileQuery();

  return (
    <div className="font-[NouvelRRegular] relative overflow-hidden">
      <div
        className={`flex flex-col ${props.isReversed ? 'lg:flex-row-reverse' : 'lg:flex-row'}`}
      >
        <div
          className={`flex-1 mx-[30px] mt-10 md:mt-20 flex flex-col gap-10 ${props.isReversed ? 'md:text-right md:mr-[100px] md:ml-[8vw]' : 'md:text-left md:mr-[12vw] md:ml-[100px]'}`}
        >
          <div
            className={`font-bold ${props.lang === 'de' ? 'text-xl' : 'text-5xl'} lg:text-6xl md:text-5xl md:text-[64px] flex flex-col leading-[55px]`}
          >
            <Slide cascade>
              <span
                className={`text-primary ${props.lang === 'de' ? 'text-xl' : 'text-5xl'}`}
              >
                {props.title.primary}
              </span>
              <span>{props.title.secondary}</span>
            </Slide>
          </div>
          <div
            className={`w-[110px] h-[2px] bg-primary ${props.isReversed ? 'self-end' : 'self-start'}`}
          />
          <Slide cascade direction={'right'}>
            <span
              className={`font-bold ${props.lang === 'de' ? 'text-xl' : 'text-3xl'} md:text-4xl`}
            >
              {props.subtitle}
            </span>
          </Slide>
          <div className="text-lg md:text-xl mb-10 md:mb-40">
            <Fade
              duration={2000}
              className={'text-lg md:text-xl mb-10 md:mb-40'}
            >
              {props.content.text}
            </Fade>
          </div>
        </div>
        <img
          loading={'lazy'}
          className="w-full lg:w-1/2 object-cover object-center"
          src={props.content.imageSource}
          alt={'img_source'}
        />
      </div>
      <div
        className={`relative bottom-0 -translate-y-24 md:-translate-y-1/2 ${props.lang === 'de' ? 'text-xl' : 'text-3xl'} flex flex-col`}
      >
        {!isMobile && (
          <span
            className={`${props.isDark ? 'bg-primary text-white' : 'bg-black'} text-white px-9 py-2 text-center ${props.isReversed ? 'self-end mr-[100px]' : 'self-start ml-[100px]'}`}
          >
            {props.iconSectionTitle}
          </span>
        )}

        <div
          className={`font-semibold flex w-full flex-col lg:flex-row gap-10 lg:gap-6 px-6 lg:py-9 lg:px-4 ${props.isDark ? 'bg-black/75 text-white' : 'bg-[#EEEEEE]/50'}  backdrop-blur-lg lg:mx-8 ${props.isReversed ? 'md:mr-0' : 'md:ml-0'} md:mr-[100px] py-10'`}
        >
          {isMobile && (
            <span
              className={`${props.isDark ? 'bg-primary text-white' : 'bg-black'} ${props.lang === 'de' ? 'text-md' : 'text-4xl'} text-white px-9 py-2 mt-6 text-center`}
            >
              {props.iconSectionTitle}
            </span>
          )}
          <Slide cascade direction={'down'} duration={300}>
            {props.icons.map((icon) => (
              <a href={icon.link} download={icon.label} key={icon.soruce}>
                <div
                  className={`flex flex-1 gap-3 items-center text-center md:text-left ${props.isDark ? 'flex-row md:items-center' : 'flex-col md:items-stretch'}`}
                >
                  <img
                    loading={'lazy'}
                    src={icon.soruce}
                    width={icon.width}
                    height={icon.height}
                    alt={'icon'}
                  />
                  <span className={'text-2xl'}>{icon.label}</span>
                </div>
              </a>
            ))}
          </Slide>
        </div>
      </div>
    </div>
  );
};

export default InfoSection;
