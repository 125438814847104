import { useTranslation } from 'react-i18next';

import {
  EN15085,
  ISO9001,
  ISO14001,
  ISO27001,
  ISO45001,
} from '../../../assets/docs';
import { ArrowDownFilled } from '../../../assets/images/home-page';
import { Meeting } from '../../../assets/images/quality';
import InfoSection from '../../../components/info-section/info-section';

const OurCertificatesSection = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  return (
    <InfoSection
      title={{
        primary: t('quality.info.titlePart1'),
        secondary: t('quality.info.titlePart2'),
      }}
      lang={lang}
      iconSectionTitle={t('quality.info.iconsTitle')}
      icons={[
        {
          label: 'EN ISO 9001:2015',
          soruce: ArrowDownFilled,
          width: 80,
          height: 80,
          link: ISO9001,
        },
        {
          label: 'EN ISO 14001:2015',
          soruce: ArrowDownFilled,
          width: 80,
          height: 80,
          link: ISO14001,
        },
        {
          label: 'EN ISO 45001:2018',
          soruce: ArrowDownFilled,
          width: 80,
          height: 80,
          link: ISO45001,
        },
        {
          label: 'EN ISO IEC 27001:2013',
          soruce: ArrowDownFilled,
          width: 80,
          height: 80,
          link: ISO27001,
        },
        {
          label: 'EN 15085-2:2020',
          soruce: ArrowDownFilled,
          width: 80,
          height: 80,
          link: EN15085,
        },
      ]}
      content={{
        text: t('quality.info.long'),
        imageSource: Meeting,
      }}
      subtitle={t('quality.info.subtitle')}
      isDark
    />
  );
};

export default OurCertificatesSection;
